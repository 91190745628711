import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from "../../firebase";
import { addDoc, collection } from "firebase/firestore";
import { toast } from "react-toastify";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  maxHeight: "80vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
};

const input = {
  margin: 0,
};

const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1 MB

const optimizeImage = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        canvas.toBlob(
          (blob) => {
            if (blob.size <= MAX_FILE_SIZE) {
              resolve(blob);
            } else {
              // Resize image if it exceeds the max file size
              const scale = Math.sqrt(MAX_FILE_SIZE / blob.size);
              canvas.width = img.width * scale;
              canvas.height = img.height * scale;
              ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
              canvas.toBlob(
                (optimizedBlob) => {
                  resolve(optimizedBlob);
                },
                "image/jpeg",
                0.7
              ); // Quality is set to 0.7 (70%)
            }
          },
          "image/jpeg",
          0.7
        ); // Quality is set to 0.7 (70%)
      };
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

const generateUniqueName = (originalName) => {
  const extension = originalName.split(".").pop();
  const uniqueId = Date.now(); // Use current timestamp as unique ID
  return `${uniqueId}.${extension}`;
};

const AddBannerModal = ({ open, handleClose, fetchBanners, categories }) => {
  const [loading, setLoading] = useState(false);
  const [colorTextBtn, setColorTextBtn] = useState("#000000");
  const [titleColor, setTitleColor] = useState("#000000");

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      linkType: "",
      colorText: "#000000",
      colorBanner: "#ffffff",
      colorTextBtn: "#000000",
      titleColor: "#000000", // Add titleColor field to defaultValues
      category: "",
      webLink: "", // Add webLink field to defaultValues
    },
  });

  const linkType = watch("linkType");
  const watchColorTextBtn = watch("colorTextBtn");
  const watchTitleColor = watch("titleColor");

  useEffect(() => {
    setColorTextBtn(watchColorTextBtn); // Update state when form value changes
    setTitleColor(watchTitleColor); // Update state when form value changes
  }, [watchColorTextBtn, watchTitleColor]);

  useEffect(() => {
    if (linkType === "category") {
      setValue("category", "");
    }
    if (linkType !== "web-link") {
      setValue("webLink", ""); // Clear webLink if linkType is not "web-link"
    }
  }, [linkType, setValue]);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const imageFile = data.image?.[0]; // Ensure data.image is a FileList
      if (!imageFile) {
        throw new Error("Image es requerido");
      }

      // Validate file size
      if (imageFile.size > MAX_FILE_SIZE) {
        toast.error("El peso de la imagen es mayor a 1MB!");
        throw new Error("El peso de la imagen es mayor a 1MB");
      }

      // Optimize image
      const optimizedImage = await optimizeImage(imageFile);

      // Generate unique file name
      const uniqueFileName = generateUniqueName(imageFile.name);

      const storage = getStorage();
      const storageRef = ref(storage, `banners/${uniqueFileName}`);
      await uploadBytes(storageRef, optimizedImage);
      const imageUrl = await getDownloadURL(storageRef);

      await addDoc(collection(db, "banners"), {
        title: data.title,
        description: data.description,
        textBtn: data.textBtn,
        colorText: data.colorText,
        colorBanner: data.colorBanner,
        colorTextBtn: data.colorTextBtn,
        titleColor: data.titleColor, // Add titleColor field
        imageUrl: imageUrl,
        linkType: data.linkType,
        category: data.category,
        webLink: data.webLink, // Add webLink field
        status: 0,
      });

      reset();
      handleClose();
      fetchBanners();
      toast.success("Banner agregado satisfactoriamente!");
    } catch (error) {
      console.error("Error uploading file: ", error);
      toast.error("Error adding banner!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <h2 id="modal-modal-title" style={{ marginBottom: 10 }}>
          Nuevo banner
        </h2>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ display: "flex", flexDirection: "column", gap: 15 }}
        >
          <TextField
            {...register("title", { required: "Title es requerido" })}
            label="Title"
            variant="outlined"
            fullWidth
            margin="normal"
            sx={input}
            error={!!errors.title}
            size="small"
            helperText={errors.title?.message}
          />
          <TextField
            {...register("description", {
              required: "Description is required",
              maxLength: {
                value: 60,
                message: "La descripcion no puede tener mas de 60 caracteres",
              },
            })}
            label="Description"
            variant="outlined"
            fullWidth
            sx={input}
            margin="normal"
            error={!!errors.description}
            size="small"
            helperText={errors.description?.message}
          />
          <TextField
            {...register("textBtn", { required: "Button Text es requerido" })}
            label="Button Text"
            variant="outlined"
            fullWidth
            sx={input}
            margin="normal"
            error={!!errors.textBtn}
            size="small"
            helperText={errors.textBtn?.message}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 10,
              marginTop: 10,
            }}
          >
            <TextField
              {...register("titleColor", {
                required: "Title Color es requerido",
                pattern: {
                  value: /^#[0-9A-Fa-f]{6}$|^#[0-9A-Fa-f]{3}$/,
                  message:
                    "El color debe empezar con # y tener 3 o 6 caracteres de largo",
                },
              })}
              label="Title Color"
              variant="outlined"
              fullWidth
              margin="normal"
              sx={input}
              error={!!errors.titleColor}
              size="small"
              helperText={errors.titleColor?.message}
              InputProps={{
                endAdornment: (
                  <div
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: watchTitleColor,
                      marginRight: -8,
                      border: "1px solid",
                      borderRadius: 5,
                    }}
                  />
                ),
              }}
            />
            <TextField
              {...register("colorText", {
                required: "Text Color es requerido",
                pattern: {
                  value: /^#[0-9A-Fa-f]{6}$|^#[0-9A-Fa-f]{3}$/,
                  message:
                    "El color debe empezar con # y tener 3 o 6 caracteres de largo",
                },
              })}
              label="Text Color"
              variant="outlined"
              fullWidth
              margin="normal"
              sx={input}
              error={!!errors.colorText}
              size="small"
              helperText={errors.colorText?.message}
              InputProps={{
                endAdornment: (
                  <div
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: watch("colorText"),
                      marginRight: -8,
                      border: "1px solid",
                      borderRadius: 5,
                    }}
                  />
                ),
              }}
            />
            <TextField
              {...register("colorBanner", {
                required: "Banner Color is required",
                pattern: {
                  value: /^#[0-9A-Fa-f]{6}$|^#[0-9A-Fa-f]{3}$/,
                  message:
                    "El color debe empezar con # y tener 3 o 6 caracteres de largo",
                },
              })}
              label="Banner Color"
              variant="outlined"
              fullWidth
              margin="normal"
              sx={input}
              error={!!errors.colorBanner}
              size="small"
              helperText={errors.colorBanner?.message}
              InputProps={{
                endAdornment: (
                  <div
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: watch("colorBanner"),
                      marginRight: -8,
                      border: "1px solid",
                      borderRadius: 5,
                    }}
                  />
                ),
              }}
            />{" "}
            <TextField
              {...register("colorTextBtn", {
                required: "Button Text Color es requerido",
                pattern: {
                  value: /^#[0-9A-Fa-f]{6}$|^#[0-9A-Fa-f]{3}$/,
                  message:
                    "El color debe empezar con # y tener 3 o 6 caracteres de largo",
                },
              })}
              label="Button Text Color"
              variant="outlined"
              fullWidth
              margin="normal"
              sx={input}
              error={!!errors.colorTextBtn}
              size="small"
              helperText={errors.colorTextBtn?.message}
              InputProps={{
                endAdornment: (
                  <div
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: watchColorTextBtn,
                      marginRight: -8,
                      border: "1px solid",
                      borderRadius: 5,
                    }}
                  />
                ),
              }}
            />
          </div>

          <TextField
            select
            {...register("linkType", { required: "Link Type es requerido" })}
            label="Link Type"
            variant="outlined"
            fullWidth
            margin="normal"
            sx={input}
            error={!!errors.linkType}
            size="small"
            helperText={errors.linkType?.message}
          >
            <MenuItem value="category">Category</MenuItem>
            <MenuItem value="web-link">Web Link</MenuItem>
            <MenuItem value="no-link">No Link</MenuItem>
          </TextField>
          {linkType === "category" && (
            <TextField
              select
              {...register("category", { required: "Category es requerido" })}
              label="Category"
              variant="outlined"
              fullWidth
              margin="normal"
              sx={input}
              error={!!errors.category}
              size="small"
              helperText={errors.category?.message}
            >
              {categories.map((cat) => (
                <MenuItem key={cat.id} value={cat.id}>
                  {cat.nombre}
                </MenuItem>
              ))}
            </TextField>
          )}
          {linkType === "web-link" && (
            <TextField
              {...register("webLink", {
                required: "Web Link is required",
                pattern: {
                  value: /^(https?:\/\/[^\s$.?#].[^\s]*)$/i,
                  message: "Enter a valid web address",
                },
              })}
              label="Web Link"
              variant="outlined"
              fullWidth
              margin="normal"
              sx={input}
              error={!!errors.webLink}
              size="small"
              helperText={errors.webLink?.message}
            />
          )}
          <input
            type="file"
            {...register("image", { required: "Image es requerido" })}
            accept="image/*"
            style={{ marginTop: 15 }}
          />
          <div style={{ marginTop: 20 }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Agregar banner"}
            </Button>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default AddBannerModal;
