import React, { useState, useEffect } from "react";
import { Line, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";

// Registrar los componentes necesarios de Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const Home = () => {
  const [userData, setUserData] = useState(null);
  const [salesData, setSalesData] = useState(null);
  const [productData, setProductData] = useState(null);

  useEffect(() => {
    // Simular la carga de datos
    setUserData({
      labels: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      datasets: [
        {
          label: "Usuarios",
          data: [100, 200, 165, 300, 250, 400, 0, 0, 0, 0, 0, 0],
          borderColor: "rgba(75,192,192,1)",
          backgroundColor: "rgba(75,192,192,0.2)",
          fill: true,
        },
      ],
    });

    setSalesData({
      labels: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],

      datasets: [
        {
          label: "Ventas",
          data: [50, 70, 60, 90, 80, 100, 0, 0, 0, 0, 0, 0],
          borderColor: "rgba(255,99,132,1)",
          backgroundColor: "rgba(255,99,132,0.2)",
          fill: true,
        },
      ],
    });

    setProductData({
      labels: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],

      datasets: [
        {
          label: "Productos",
          data: [10, 20, 15, 30, 25, 40, 0, 0, 0, 0, 0, 0],
          borderColor: "rgba(153,102,255,1)",
          backgroundColor: "rgba(153,102,255,0.2)",
          fill: true,
        },
      ],
    });
  }, []);

  return (
    <div style={{ padding: 20 }}>
      <h1>Home</h1>
      <p>Bienvenido a la página principal</p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
          margin: 30,
          flex: 1,
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "row", gap: 20, flex: 1 }}
        >
          <div style={{ flex: 1 }}>
            <h5>Cantidad de Usuarios Total y por Mes</h5>
            {userData ? <Line data={userData} /> : <p>Cargando datos...</p>}
          </div>
          <div style={{ flex: 1 }}>
            <h5>Cantidad de Ventas Totales y por Mes</h5>
            {salesData ? <Line data={salesData} /> : <p>Cargando datos...</p>}
          </div>

          {/* <div style={{ flex: 1 }}>
            <h5>Cantidad de Productos Total</h5>
            {productData ? (
              <Pie
                data={{
                  labels: ["Total Productos"],
                  datasets: [
                    {
                      data: [100], // Reemplaza con datos reales
                      backgroundColor: ["rgba(75,192,192,0.2)"],
                      borderColor: ["rgba(75,192,192,1)"],
                      borderWidth: 1,
                    },
                  ],
                }}
              />
            ) : (
              <p>Cargando datos...</p>
            )}
          </div> */}
          <div style={{ flex: 1 }}>
            <h5>Cantidad de Productos Agregados por Mes</h5>
            {productData ? (
              <Bar data={productData} />
            ) : (
              <p>Cargando datos...</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
