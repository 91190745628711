import React, { useState } from "react";
import { db } from "./firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LockPersonIcon from "@mui/icons-material/LockPerson";

function Login({ onLogin }) {
  const [usuario, setUsuario] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const adminRef = collection(db, "administradores");
      const q = query(
        adminRef,
        where("usuario", "==", usuario),
        where("password", "==", password)
      );
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setError("Credenciales incorrectas");
      } else {
        onLogin();
        navigate("/"); // Redirige a la página principal
      }
    } catch (err) {
      console.error("Error iniciando sesión: ", err);
      setError("Error iniciando sesión");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        background: "#efefef",
      }}
    >
      <LockPersonIcon
        style={{
          background: "#9D00B2",
          color: "#fff",
          borderRadius: 50,
          padding: 10,
          fontSize: 40,
        }}
      />
      <h2 style={{ marginBottom: 20 }}>Panel administrativo</h2>
      <div
        // component="form"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "stretch",
          width: "300px",
          gap: 20,
          padding: 20,
          borderRadius: 20,
          background: "#fff",
        }}
        // noValidate
        // autoComplete="off"
      >
        <TextField
          id="usuario"
          label="Usuario"
          variant="outlined"
          value={usuario}
          onChange={(e) => setUsuario(e.target.value)}
          style={{ width: "100%" }}
        />
        <TextField
          id="password"
          label="Contraseña"
          type="password"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{ width: "100%" }}
        />
      </div>
      <Button
        variant="contained"
        onClick={handleLogin}
        style={{ width: "300px", marginTop: 20, borderRadius: 50 }}
      >
        Ingresar
      </Button>
      {error && (
        <p style={{ color: "#f00", marginTop: 10, fontSize: 14 }}>{error}</p>
      )}
    </div>
  );
}

export default Login;
