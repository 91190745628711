import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ProductCountBadgeByCategory from "../components/ProductCountBadgeByCategory";

const CategoriesTable = ({
  categorias,
  selectedRow,
  hoveredRow,
  handleRowClick,
  handleMouseEnter,
  handleMouseLeave,
  handleEditClick,
  handleDeleteClick,
  handleAddCategoryClick,
  categoryCounts,
}) => (
  <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} aria-label="categorias table">
      <TableHead>
        <TableRow>
          <TableCell colSpan={4} className="tableHeaderCell">
            <h2 style={{ margin: 0 }}>Categorias</h2>
          </TableCell>
          <TableCell className="tableHeaderCell" style={{ textAlign: "right" }}>
            <Button
              style={{ fontSize: 12 }}
              variant="contained"
              color="primary"
              onClick={handleAddCategoryClick}
              size="small"
            >
              Agregar Categoria
            </Button>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="tableHeaderCell">
            <b>Nombre</b>
          </TableCell>
          <TableCell className="tableHeaderCell">
            <b>Icono</b>
          </TableCell>
          <TableCell className="tableHeaderCell">Productos</TableCell>
          <TableCell colSpan={2} className="tableHeaderCell"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {categorias.map((categoria) => (
          <TableRow
            key={categoria.id}
            onClick={() => handleRowClick(categoria.id)}
            onMouseEnter={() => handleMouseEnter(categoria.id)}
            onMouseLeave={handleMouseLeave}
            style={{
              cursor: "pointer",
              backgroundColor:
                selectedRow === categoria.id
                  ? "#e0f7fa"
                  : hoveredRow === categoria.id
                  ? "#f0f0f0"
                  : "inherit",
            }}
          >
            <TableCell component="th" scope="row">
              {categoria.nombre}
            </TableCell>
            <TableCell component="th" scope="row">
              {categoria.icon}
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              <ProductCountBadgeByCategory categoryId={categoria.id} />
            </TableCell>
            <TableCell>
              <div style={{ display: "flex", gap: 20 }}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEditClick(categoria);
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteClick(categoria.id);
                  }}
                  disabled={categoryCounts[categoria.id] > 0}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </TableCell>
            <TableCell style={{ textAlign: "right" }}>
              <IconButton>
                <ArrowForwardIosIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default CategoriesTable;
