import React, { useEffect, useState } from "react";
import { fetchUserInfo } from "../utils/functions";

const UserInfoById = ({ userId }) => {
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const getUserInfo = async () => {
      const data = await fetchUserInfo(userId);
      setUserInfo(data);
    };

    getUserInfo();
  }, [userId]);

  return (
    <div>
      {userInfo ? (
        <div>
          <h2>User Info</h2>
          <p>
            <b>User ID:</b> {userId}
            <br />
            <b>Name:</b> {userInfo.displayName}
            <br />
            <b>Email:</b> {userInfo.email} <br />
            <b>Telefono:</b> {userInfo.telefono} <br />
            <b>Cedula:</b> {userInfo.cedula}
            <br />
            <b>Fecha de creación:</b> {userInfo.createdAt}
          </p>
          {/* Agrega aquí otros detalles del usuario según la estructura de tu colección 'users' */}
        </div>
      ) : (
        <p>Loading user information...</p>
      )}
    </div>
  );
};

export default UserInfoById;
