import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { db } from "../../firebase";
import { updateDoc, doc, getDocs, collection } from "firebase/firestore";
import { toast } from "react-toastify";
import { useMediaQuery } from "@mui/material";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  maxHeight: "80vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
};

const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1 MB

const optimizeImage = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        canvas.toBlob(
          (blob) => {
            if (blob.size <= MAX_FILE_SIZE) {
              resolve(blob);
            } else {
              // Resize image if it exceeds the max file size
              const scale = Math.sqrt(MAX_FILE_SIZE / blob.size);
              canvas.width = img.width * scale;
              canvas.height = img.height * scale;
              ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
              canvas.toBlob(
                (optimizedBlob) => {
                  resolve(optimizedBlob);
                },
                "image/jpeg",
                0.7
              ); // Quality is set to 0.7 (70%)
            }
          },
          "image/jpeg",
          0.7
        ); // Quality is set to 0.7 (70%)
      };
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

const BannerDetailModal = ({ open, handleClose, banner, fetchBanners }) => {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: banner.title,
      description: banner.description,
      textBtn: banner.textBtn,
      colorText: banner.colorText,
      colorBanner: banner.colorBanner,
      colorTextBtn: banner.colorTextBtn,
      titleColor: banner.titleColor,
      linkType: banner.linkType,
      category: banner.category || "", // Ensure this is correctly set
      webLink: banner.webLink || "",
    },
  });

  const watchLinkType = watch("linkType");
  const watchColorText = watch("colorText");
  const watchColorBanner = watch("colorBanner");
  const watchColorTextBtn = watch("colorTextBtn");
  const watchTitleColor = watch("titleColor");

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "categorias"));
        const categoryList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          nombre: doc.data().nombre,
        }));
        setCategories(categoryList);
        // Set the default category selection
        if (banner.category) {
          setValue("category", banner.category);
        }
      } catch (error) {
        console.error("Error fetching categories: ", error);
      }
    };

    fetchCategories();
  }, [banner.category, setValue]);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const imageFile = data.image?.[0];
      const storage = getStorage();
      const bannerRef = doc(db, "banners", banner.id);

      if (imageFile) {
        // Delete old image if it exists
        if (banner.imageUrl) {
          const oldImageRef = ref(storage, banner.imageUrl);
          await deleteObject(oldImageRef);
        }

        // Optimize and upload new image
        const optimizedImage = await optimizeImage(imageFile);
        const imageRef = ref(
          storage,
          `banners/${Date.now()}_${imageFile.name}`
        );
        await uploadBytes(imageRef, optimizedImage);
        const imageUrl = await getDownloadURL(imageRef);

        data.imageUrl = imageUrl; // Add new image URL to data
      } else {
        data.imageUrl = banner.imageUrl; // Keep the existing image URL if no new image is selected
      }

      // Remove the image field from data if not needed
      const { image, ...updateData } = data;

      await updateDoc(bannerRef, {
        ...updateData,
        imageUrl: data.imageUrl,
      });

      reset();
      handleClose();
      fetchBanners();
      toast.success("Banner updated successfully!");
    } catch (error) {
      console.error("Error updating banner: ", error);
      toast.error("Error updating banner!");
    } finally {
      setLoading(false);
    }
  };

  const isSmallScreen = useMediaQuery("(max-width:800px)");

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <h2 id="modal-modal-title">Editar Banner</h2>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ display: "flex", flexDirection: "column", gap: 15 }}
        >
          {banner.imageUrl && (
            <img
              src={banner.imageUrl}
              alt="Banner"
              style={{
                width: 200,
                height: 200,
                margin: "0 auto 15px auto",
                border: "2px solid",
              }}
            />
          )}
          <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
            <label style={{ fontSize: 12, marginLeft: 15 }}>
              Seleccionar nueva imagen
            </label>
            <input
              type="file"
              {...register("image")}
              style={{
                margin: 0,
                border: "1px solid",
                borderColor: "#eee",
                padding: 5,
                borderRadius: "5px",
              }}
              accept="image/*"
            />
          </div>
          <TextField
            {...register("title", { required: "Title is required" })}
            label="Title"
            variant="outlined"
            fullWidth
            margin="normal"
            error={!!errors.title}
            size="small"
            helperText={errors.title?.message}
          />
          <TextField
            {...register("description", {
              required: "Description is required",
            })}
            label="Description"
            variant="outlined"
            fullWidth
            margin="normal"
            error={!!errors.description}
            size="small"
            helperText={errors.description?.message}
          />
          <TextField
            {...register("textBtn", { required: "Button Text is required" })}
            label="Button Text"
            variant="outlined"
            fullWidth
            margin="normal"
            error={!!errors.textBtn}
            size="small"
            helperText={errors.textBtn?.message}
          />
          <div
            style={{
              display: "flex",
              gap: 10,
              flexDirection: isSmallScreen ? "column" : "row",
            }}
          >
            <TextField
              {...register("titleColor", {
                required: "Title Color es requerido",
                pattern: {
                  value: /^#[0-9A-Fa-f]{6}$|^#[0-9A-Fa-f]{3}$/,
                  message:
                    "El color debe empezar con # y tener 3 o 6 caracteres de largo",
                },
              })}
              label="Title Color"
              variant="outlined"
              fullWidth
              margin="normal"
              error={!!errors.titleColor}
              size="small"
              helperText={errors.titleColor?.message}
              InputProps={{
                endAdornment: (
                  <div
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: watchTitleColor,
                      marginRight: -8,
                      border: "1px solid",
                      borderRadius: 5,
                    }}
                  />
                ),
              }}
            />
            <TextField
              {...register("colorText", {
                required: "Text Color es requerido",
                pattern: {
                  value: /^#[0-9A-Fa-f]{6}$|^#[0-9A-Fa-f]{3}$/,
                  message:
                    "El color debe empezar con # y tener 3 o 6 caracteres de largo",
                },
              })}
              label="Text Color"
              variant="outlined"
              fullWidth
              margin="normal"
              error={!!errors.colorText}
              size="small"
              helperText={errors.colorText?.message}
              InputProps={{
                endAdornment: (
                  <div
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: watchColorText,
                      marginRight: -8,
                      border: "1px solid",
                      borderRadius: 5,
                    }}
                  />
                ),
              }}
            />
            <TextField
              {...register("colorTextBtn", {
                required: "Button Text Color es requerido",
                pattern: {
                  value: /^#[0-9A-Fa-f]{6}$|^#[0-9A-Fa-f]{3}$/,
                  message:
                    "El color debe empezar con # y tener 3 o 6 caracteres de largo",
                },
              })}
              label="Button Text Color"
              variant="outlined"
              fullWidth
              margin="normal"
              error={!!errors.colorTextBtn}
              size="small"
              helperText={errors.colorTextBtn?.message}
              InputProps={{
                endAdornment: (
                  <div
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: watchColorTextBtn,
                      marginRight: -8,
                      border: "1px solid",
                      borderRadius: 5,
                    }}
                  />
                ),
              }}
            />
            <TextField
              {...register("colorBanner", {
                required: "Banner Color es requerido",
                pattern: {
                  value: /^#[0-9A-Fa-f]{6}$|^#[0-9A-Fa-f]{3}$/,
                  message:
                    "El color debe empezar con # y tener 3 o 6 caracteres de largo",
                },
              })}
              label="Banner Color"
              variant="outlined"
              fullWidth
              margin="normal"
              error={!!errors.colorBanner}
              size="small"
              helperText={errors.colorBanner?.message}
              InputProps={{
                endAdornment: (
                  <div
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: watchColorBanner,
                      marginRight: -8,
                      border: "1px solid",
                      borderRadius: 5,
                    }}
                  />
                ),
              }}
            />
          </div>
          <TextField
            select
            {...register("linkType", { required: "Link Type is required" })}
            label="Link Type"
            variant="outlined"
            fullWidth
            margin="normal"
            error={!!errors.linkType}
            size="small"
            helperText={errors.linkType?.message}
            value={watch("linkType")} // Set the value to the current linkType
          >
            <MenuItem value="category">Category</MenuItem>
            <MenuItem value="web-link">Web Link</MenuItem>
            <MenuItem value="no-link">No Link</MenuItem>
          </TextField>

          {watchLinkType === "category" && (
            <TextField
              select
              {...register("category", { required: "Category is required" })}
              label="Category"
              variant="outlined"
              fullWidth
              margin="normal"
              error={!!errors.category}
              size="small"
              helperText={errors.category?.message}
              value={watch("category")} // Set the value of the select to match the category ID
            >
              {categories.map((cat) => (
                <MenuItem key={cat.id} value={cat.id}>
                  {cat.nombre}
                </MenuItem>
              ))}
            </TextField>
          )}
          {watchLinkType === "web-link" && (
            <TextField
              {...register("webLink", { required: "Web Link is required" })}
              label="Web Link"
              variant="outlined"
              fullWidth
              margin="normal"
              error={!!errors.webLink}
              size="small"
              helperText={errors.webLink?.message}
            />
          )}
          <div
            style={{
              display: "flex",
              flexDirection: isSmallScreen ? "column" : "row",
              justifyContent: "space-between",
              gap: 10,
            }}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Guardar cambios"}
            </Button>
            <Button
              type="button"
              variant="outlined"
              color="secondary"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default BannerDetailModal;
