import React, { useCallback, useEffect } from "react";
import { Badge, IconButton } from "@mui/material";
import InventoryIcon from "@mui/icons-material/Inventory";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase"; // Asegúrate de importar tu instancia de Firestore
import { useNavigate, useLocation } from "react-router-dom"; // Importa useNavigate y useLocation
import { useProductStatus } from "../ProductStatusContext"; // Importa el contexto

export const NumStatusCeroProductAppBar = () => {
  const { numStatusCero, setNumStatusCero } = useProductStatus(); // Obtén el estado y la función para actualizarlo
  const navigate = useNavigate(); // Para navegación programática
  const location = useLocation(); // Para verificar la ubicación actual

  // Función para actualizar el título de la pestaña
  const updateTitleWithBadge = useCallback((numProducts) => {
    if (numProducts > 0) {
      document.title = `(${numProducts}) Productos pendientes`;
    } else {
      document.title = "El Punto | Administrador";
    }
  }, []);

  const fetchProductCount = useCallback(async () => {
    try {
      const productsCollection = collection(db, "productos");
      const q = query(productsCollection, where("status", "==", 0));
      const querySnapshot = await getDocs(q);
      const productCount = querySnapshot.size;
      setNumStatusCero(productCount); // Actualiza el estado global
      updateTitleWithBadge(productCount); // Actualiza el título con el número de productos
    } catch (error) {
      console.error("Error fetching product count:", error);
    }
  }, [setNumStatusCero, updateTitleWithBadge]);

  useEffect(() => {
    // Inicializa el conteo de productos
    fetchProductCount();

    // Configura el intervalo para actualizar cada 20 segundos
    const intervalId = setInterval(() => {
      fetchProductCount();
    }, 20000); // 20,000 ms = 20 segundos

    // Limpia el intervalo al desmontar el componente
    return () => clearInterval(intervalId);
  }, [fetchProductCount]);

  const handleClick = () => {
    if (location.pathname === "/ProductsScreen") {
      // Si ya estás en ProductsScreen, recarga la página
      window.location.reload();
    } else {
      // Navega a ProductsScreen
      navigate("/ProductsScreen");
    }
  };

  return (
    <IconButton aria-label="products with status 0" onClick={handleClick}>
      <Badge badgeContent={numStatusCero} color="error">
        <InventoryIcon style={{ color: "#fff" }} />
      </Badge>
    </IconButton>
  );
};
