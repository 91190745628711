import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import CategoriesTable from "../components/CategoriesTable";
import SubcategoriesTable from "../components/SubcategoriesTable";
import EditCategoryModal from "../components/EditCategoryModal";
import EditSubcategoryModal from "../components/EditSubcategoryModal";
import AddCategoryModal from "../components/AddCategoryModal";
import AddSubcategoryModal from "../components/AddSubcategoryModal";
import ConfirmationModal from "../components/shared/ConfirmationModal";
import "../styles/styles.css";
import {
  getProductCountByCategory,
  getProductCountBySubcategory,
} from "../utils/functions";

function CategorysScreen() {
  const [categorias, setCategorias] = useState([]);
  const [subcategorias, setSubcategorias] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [categoryToEdit, setCategoryToEdit] = useState(null);
  const [editSubcategoryModalOpen, setEditSubcategoryModalOpen] =
    useState(false);
  const [subcategoryToEdit, setSubcategoryToEdit] = useState(null);
  const [addCategoryModalOpen, setAddCategoryModalOpen] = useState(false);
  const [addSubcategoryModalOpen, setAddSubcategoryModalOpen] = useState(false);
  const [categoryCounts, setCategoryCounts] = useState({});
  const [subcategoryCounts, setSubcategoryCounts] = useState({});
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [isCategoryDeletion, setIsCategoryDeletion] = useState(true);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const fetchCategoriasAndCounts = async () => {
      try {
        const categoriasCollection = collection(db, "categorias");
        const categoriasSnapshot = await getDocs(categoriasCollection);
        const categoriasData = categoriasSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCategorias(categoriasData);

        // Fetch product counts for categories
        const counts = await Promise.all(
          categoriasData.map(async (categoria) => {
            const count = await getProductCountByCategory(categoria.id);
            return { id: categoria.id, count };
          })
        );
        const categoryCountsMap = counts.reduce((acc, { id, count }) => {
          acc[id] = count;
          return acc;
        }, {});
        setCategoryCounts(categoryCountsMap);
      } catch (error) {
        console.error("Error fetching categorias:", error);
      }
    };

    fetchCategoriasAndCounts();
  }, []);

  const fetchSubcategorias = async (categoryId) => {
    try {
      const categoriaDocRef = doc(db, "categorias", categoryId);
      const subcategoriasCollection = collection(
        categoriaDocRef,
        "subcategorias"
      );
      const subcategoriasSnapshot = await getDocs(subcategoriasCollection);
      const subcategoriasData = subcategoriasSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSubcategorias(subcategoriasData);

      // Fetch product counts for subcategories
      const counts = await Promise.all(
        subcategoriasData.map(async (subcategoria) => {
          const count = await getProductCountBySubcategory(subcategoria.id);
          return { id: subcategoria.id, count };
        })
      );
      const subcategoryCountsMap = counts.reduce((acc, { id, count }) => {
        acc[id] = count;
        return acc;
      }, {});
      setSubcategoryCounts(subcategoryCountsMap);
    } catch (error) {
      console.error("Error fetching subcategorias:", error);
    }
  };

  const handleAddCategoryClick = () => setAddCategoryModalOpen(true);
  const handleAddSubcategoryClick = () => setAddSubcategoryModalOpen(true);

  const handleSaveNewCategory = async (newCategory) => {
    try {
      const newCategoryRef = doc(collection(db, "categorias"));
      await setDoc(newCategoryRef, {
        nombre: newCategory.nombre,
        icon: newCategory.icon,
      });
      setCategorias((prevCategories) => [
        ...prevCategories,
        { id: newCategoryRef.id, ...newCategory },
      ]);
      setAddCategoryModalOpen(false);
    } catch (error) {
      console.error("Error adding new category:", error);
    }
  };

  const handleSaveNewSubcategory = async (newSubcategory) => {
    try {
      const newSubcategoryRef = doc(
        collection(db, "categorias", selectedRow, "subcategorias")
      );
      await setDoc(newSubcategoryRef, {
        nombre: newSubcategory.nombre,
      });
      setSubcategorias((prevSubcategories) => [
        ...prevSubcategories,
        { id: newSubcategoryRef.id, ...newSubcategory },
      ]);
      setAddSubcategoryModalOpen(false);
    } catch (error) {
      console.error("Error adding new subcategory:", error);
    }
  };

  const handleSaveCategory = async (updatedCategory) => {
    try {
      const categoryDocRef = doc(db, "categorias", updatedCategory.id);
      await updateDoc(categoryDocRef, {
        nombre: updatedCategory.nombre,
        icon: updatedCategory.icon,
      });
      setCategorias((prevCategories) =>
        prevCategories.map((cat) =>
          cat.id === updatedCategory.id ? updatedCategory : cat
        )
      );
      setEditModalOpen(false);
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };

  const handleSaveSubcategory = async (updatedSubcategory) => {
    try {
      const subcategoryDocRef = doc(
        db,
        "categorias",
        selectedRow,
        "subcategorias",
        updatedSubcategory.id
      );
      await updateDoc(subcategoryDocRef, {
        nombre: updatedSubcategory.nombre,
      });
      setSubcategorias((prevSubcategories) =>
        prevSubcategories.map((sub) =>
          sub.id === updatedSubcategory.id ? updatedSubcategory : sub
        )
      );
      setEditSubcategoryModalOpen(false);
    } catch (error) {
      console.error("Error updating subcategory:", error);
    }
  };

  const handleDeleteCategory = async () => {
    setLoading(true);
    try {
      const categoryId = itemToDelete;
      const subcategoriesCollection = collection(
        db,
        "categorias",
        categoryId,
        "subcategorias"
      );
      const subcategoriesSnapshot = await getDocs(subcategoriesCollection);
      const subcategoryIds = subcategoriesSnapshot.docs.map((doc) => doc.id);

      await Promise.all(
        subcategoryIds.map((subcategoryId) =>
          deleteDoc(
            doc(db, "categorias", categoryId, "subcategorias", subcategoryId)
          )
        )
      );

      await deleteDoc(doc(db, "categorias", categoryId));

      setCategorias((prevCategories) =>
        prevCategories.filter((cat) => cat.id !== categoryId)
      );
      setSelectedRow(null);
      setMessage("Categoría eliminada con éxito.");
    } catch (error) {
      setMessage("Error al eliminar la categoría.");
      console.error("Error deleting category:", error);
    } finally {
      setLoading(false);
      setShowConfirmationModal(false);
      setItemToDelete(null);
    }
  };

  const handleDeleteSubcategory = async () => {
    setLoading(true);
    try {
      const subcategoryId = itemToDelete;
      await deleteDoc(
        doc(db, "categorias", selectedRow, "subcategorias", subcategoryId)
      );

      setSubcategorias((prevSubcategories) =>
        prevSubcategories.filter((sub) => sub.id !== subcategoryId)
      );
      setMessage("Subcategoría eliminada con éxito.");
    } catch (error) {
      setMessage("Error al eliminar la subcategoría.");
      console.error("Error deleting subcategory:", error);
    } finally {
      setLoading(false);
      setShowConfirmationModal(false);
      setItemToDelete(null);
    }
  };

  const handleDeleteClick = (itemId, isCategory = true) => {
    setItemToDelete(itemId);
    setIsCategoryDeletion(isCategory);
    setShowConfirmationModal(true);
  };

  const handleConfirmationModalCancel = () => {
    setShowConfirmationModal(false);
    setItemToDelete(null);
  };

  const handleConfirmationModalConfirm = () => {
    if (isCategoryDeletion) {
      handleDeleteCategory();
    } else {
      handleDeleteSubcategory();
    }
  };

  const handleMouseEnter = (categoryId) => {
    setHoveredRow(categoryId);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };

  return (
    <>
      <h2 className="titlePageInt">Categorías y Subcategorías</h2>
      <div style={{ display: "flex", padding: 20, gap: 10 }}>
        <div>
          <CategoriesTable
            categorias={categorias}
            selectedRow={selectedRow}
            hoveredRow={hoveredRow}
            handleRowClick={(categoryId) => {
              fetchSubcategorias(categoryId);
              setSelectedRow(categoryId);
            }}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            handleEditClick={(category) => {
              setCategoryToEdit(category);
              setEditModalOpen(true);
            }}
            handleDeleteClick={(categoryId) =>
              handleDeleteClick(categoryId, true)
            }
            handleAddCategoryClick={handleAddCategoryClick}
            categoryCounts={categoryCounts}
          />
        </div>

        {selectedRow && (
          <div>
            <SubcategoriesTable
              subcategorias={subcategorias}
              hoveredRow={hoveredRow}
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
              handleEditSubcategoryClick={(subcategory) => {
                setSubcategoryToEdit(subcategory);
                setEditSubcategoryModalOpen(true);
              }}
              handleDeleteSubcategoryClick={(subcategoryId) =>
                handleDeleteClick(subcategoryId, false)
              }
              handleAddSubcategoryClick={handleAddSubcategoryClick}
              subcategoryCounts={subcategoryCounts}
            />
          </div>
        )}

        {categoryToEdit && (
          <EditCategoryModal
            open={editModalOpen}
            onClose={() => setEditModalOpen(false)}
            category={categoryToEdit}
            onSave={handleSaveCategory}
          />
        )}
        {subcategoryToEdit && (
          <EditSubcategoryModal
            open={editSubcategoryModalOpen}
            onClose={() => setEditSubcategoryModalOpen(false)}
            subcategory={subcategoryToEdit}
            onSave={handleSaveSubcategory}
          />
        )}
        <AddCategoryModal
          open={addCategoryModalOpen}
          onClose={() => setAddCategoryModalOpen(false)}
          onSave={handleSaveNewCategory}
        />
        <AddSubcategoryModal
          open={addSubcategoryModalOpen}
          onClose={() => setAddSubcategoryModalOpen(false)}
          onSave={handleSaveNewSubcategory}
        />
        <ConfirmationModal
          isVisible={showConfirmationModal}
          onConfirm={handleConfirmationModalConfirm}
          onCancel={handleConfirmationModalCancel}
          message={
            isCategoryDeletion
              ? "¿Estás seguro de que deseas eliminar esta categoría? Esto también eliminará todas sus subcategorías."
              : "¿Estás seguro de que deseas eliminar esta subcategoría?"
          }
          isLoading={loading}
          successMessage={message}
        />
      </div>
    </>
  );
}

export default CategorysScreen;
