// src/components/ProductCountBadgeByCategory.js
import React, { useEffect, useState } from "react";
import { db } from "../firebase"; // Ajusta la ruta según la ubicación de tu archivo firebase
import { collection, query, where, getDocs } from "firebase/firestore";
import Badge from "@mui/material/Badge";

// Función para obtener el conteo de productos por categoría
const getProductCountByCategory = async (categoryId) => {
  try {
    if (!categoryId) {
      console.error("CategoryId is missing.");
      return 0;
    }

    const productsCollection = collection(db, "productos");
    const q = query(productsCollection, where("categoria", "==", categoryId));
    const querySnapshot = await getDocs(q);

    return querySnapshot.size; // Retorna el conteo de documentos
  } catch (error) {
    console.error("Error fetching product count:", error);
    return 0; // En caso de error, retorna 0
  }
};

const ProductCountBadgeByCategory = ({ categoryId }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const fetchProductCount = async () => {
      try {
        const count = await getProductCountByCategory(categoryId);
        setCount(count);
      } catch (error) {
        console.error("Error fetching product count:", error);
        setCount(0); // En caso de error, establecer conteo a 0
      }
    };

    fetchProductCount();
  }, [categoryId]);

  return <Badge badgeContent={count} color="secondary" />;
};

export default ProductCountBadgeByCategory;
