import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ProductCountBadgeBySubcategory from "../components/ProductCountBadgeBySubcategory";

const SubcategoriesTable = ({
  subcategorias,
  hoveredRow,
  handleMouseEnter,
  handleMouseLeave,
  handleEditSubcategoryClick,
  handleDeleteSubcategoryClick,
  handleAddSubcategoryClick,
  subcategoryCounts,
}) => (
  <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} aria-label="subcategorias table">
      <TableHead>
        <TableRow>
          <TableCell colSpan={2} className="tableHeaderCell">
            <h2 style={{ margin: 0 }}>Subcategorias</h2>
          </TableCell>
          <TableCell className="tableHeaderCell">
            <Button
              style={{ fontSize: 12 }}
              variant="contained"
              color="primary"
              onClick={handleAddSubcategoryClick}
              size="small"
            >
              Agregar Subcategoria
            </Button>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="tableHeaderCell">
            <b>Nombre</b>
          </TableCell>
          <TableCell className="tableHeaderCell">Productos</TableCell>
          <TableCell className="tableHeaderCell"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {subcategorias.map((subcategoria) => (
          <TableRow
            key={subcategoria.id}
            onMouseEnter={() => handleMouseEnter(subcategoria.id)}
            onMouseLeave={handleMouseLeave}
            style={{
              backgroundColor:
                hoveredRow === subcategoria.id ? "#f0f0f0" : "inherit",
            }}
          >
            <TableCell component="th" scope="row">
              {subcategoria.nombre}
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              <ProductCountBadgeBySubcategory subcategoryId={subcategoria.id} />
            </TableCell>
            <TableCell>
              <div style={{ display: "flex", gap: 20 }}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEditSubcategoryClick(subcategoria);
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteSubcategoryClick(subcategoria.id);
                  }}
                  disabled={subcategoryCounts[subcategoria.id] > 0}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default SubcategoriesTable;
