import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  query,
  where,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import BasicModal from "../components/BasicModal";
import UserInfoById from "./UserInfoById";
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import { toast } from "react-toastify";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";

function UsersScreen() {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [page, setPage] = useState(1);
  const [openConfirmModal, setOpenConfirmModal] = useState(false); // Nuevo estado para el modal de confirmación
  const [userToDelete, setUserToDelete] = useState(null); // Usuario seleccionado para eliminar
  const itemsPerPage = 10;

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    const results = users.filter((user) =>
      user.nombre.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredUsers(results);
    setPage(1);
  }, [searchTerm, users]);

  const fetchUsers = async () => {
    try {
      const usersCollection = collection(db, "users");
      const usersSnapshot = await getDocs(usersCollection);
      const usersData = usersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt.toDate().toLocaleDateString(),
        status: doc.data().status || 0,
      }));
      setUsers(usersData);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedUserId(null);
  };

  const handleEditClick = (userId) => {
    console.log(`Edit clicked for user with ID: ${userId}`);
  };

  const handleDeleteClick = (userId) => {
    // Abre el modal de confirmación y almacena el userId
    setUserToDelete(userId);
    setOpenConfirmModal(true);
  };

  const confirmDelete = async () => {
    try {
      // Eliminar productos asociados al usuario
      const productsQuery = query(
        collection(db, "productos"),
        where("userId", "==", userToDelete)
      );
      const productsSnapshot = await getDocs(productsQuery);

      // Eliminar cada producto
      const deleteProductsPromises = productsSnapshot.docs.map((productDoc) =>
        deleteDoc(doc(db, "productos", productDoc.id))
      );
      await Promise.all(deleteProductsPromises);

      // Eliminar el usuario
      await deleteDoc(doc(db, "users", userToDelete));

      // Actualizar el estado para remover al usuario de la lista
      setUsers(users.filter((user) => user.id !== userToDelete));

      toast.success("Usuario y productos eliminados con éxito!");
    } catch (error) {
      console.error("Error eliminando usuario o productos:", error);
      toast.error("Error eliminando el usuario o sus productos.");
    }

    // Cerrar el modal de confirmación
    setOpenConfirmModal(false);
  };

  const handleStatusChange = async (userId, currentStatus) => {
    try {
      const userRef = doc(db, "users", userId);
      await updateDoc(userRef, { status: currentStatus === 0 ? 2 : 0 });

      if (currentStatus === 0) {
        const productsQuery = query(
          collection(db, "productos"),
          where("userId", "==", userId),
          where("status", "==", 1)
        );
        const productsSnapshot = await getDocs(productsQuery);
        const productUpdates = productsSnapshot.docs.map((doc) => {
          return updateDoc(doc.ref, { status: 2 });
        });
        await Promise.all(productUpdates);
      }

      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === userId
            ? { ...user, status: currentStatus === 0 ? 2 : 0 }
            : user
        )
      );
      toast.success("Status actualizado con éxito!");
    } catch (error) {
      console.error("Error updating user status:", error);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const paginatedUsers = filteredUsers.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  return (
    <div style={{ padding: 20 }}>
      <TextField
        label="Buscar usuarios"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="tableHeaderCell">Nombre</TableCell>
              <TableCell className="tableHeaderCell">Cédula</TableCell>
              <TableCell className="tableHeaderCell">Email</TableCell>
              <TableCell className="tableHeaderCell">Teléfono</TableCell>
              <TableCell className="tableHeaderCell">
                Fecha de Creación
              </TableCell>
              <TableCell className="tableHeaderCell" colSpan={4}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedUsers.map((user) => (
              <TableRow key={user.id}>
                <TableCell component="th" scope="row">
                  {user.nombre}
                </TableCell>
                <TableCell>{user.cedula}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.telefono}</TableCell>
                <TableCell>{user.createdAt}</TableCell>
                <TableCell>
                  <Link
                    to={`/User/${user.uid}`}
                    style={{ textDecoration: "none", color: "blue" }}
                  >
                    <VisibilityIcon />
                  </Link>
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEditClick(user.id)}>
                    <EditIcon color="secondary" />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleDeleteClick(user.id)}>
                    <DeleteIcon color="warning" />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <Switch
                    checked={user.status === 0 || user.status === undefined}
                    onChange={() => handleStatusChange(user.id, user.status)}
                  />
                  {user.status === 2 ? " Inactivado" : " Activo"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        count={Math.ceil(filteredUsers.length / itemsPerPage)}
        page={page}
        onChange={handlePageChange}
        style={{ marginTop: 20, display: "flex", justifyContent: "center" }}
      />

      <BasicModal
        open={openModal}
        onClose={handleCloseModal}
        component={UserInfoById}
        componentProps={{ userId: selectedUserId }}
      />

      {/* Modal de confirmación para eliminar */}
      <Modal
        open={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            padding: 20,
            backgroundColor: "white",
            margin: "auto",
            maxWidth: 400,
            display: "flex",
            flexDirection: "column",
            gap: 15,
            marginTop: 20,
            borderRadius: 10,
          }}
        >
          <h2>Confirmar eliminación</h2>
          <p>¿Estás seguro de que deseas eliminar este usuario?</p>
          <div
            style={{
              display: "flex",
              gap: 10,
            }}
          >
            <Button
              style={{
                flex: 1,
              }}
              variant="contained"
              color="primary"
              onClick={confirmDelete}
            >
              Confirmar
            </Button>
            <Button
              style={{
                flex: 1,
              }}
              variant="outlined"
              color="secondary"
              onClick={() => setOpenConfirmModal(false)}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default UsersScreen;
