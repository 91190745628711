import React, { useState, useEffect } from "react";
import { TextField, Button, Modal, Box, Typography } from "@mui/material";
import { db } from "../../firebase";
import { collection, addDoc } from "firebase/firestore";
import "../../styles/styles.css";
import { toast } from "react-toastify";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  maxHeight: "80vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
};

const AddQuestionModal = ({ open, onClose, fetchQuestions }) => {
  const [newQuestion, setNewQuestion] = useState({
    pregunta: "",
    respuesta: "",
  });

  const [errors, setErrors] = useState({
    pregunta: "",
    respuesta: "",
  });

  useEffect(() => {
    if (open) {
      // Reiniciar el formulario y los errores cuando el modal se abra
      setNewQuestion({
        pregunta: "",
        respuesta: "",
      });
      setErrors({
        pregunta: "",
        respuesta: "",
      });
    }
  }, [open]);

  const handleInputChange = (e) => {
    setNewQuestion({ ...newQuestion, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { pregunta: "", respuesta: "" };

    console.log("Validando formulario", newQuestion); // Depuración

    // Validar el campo de pregunta
    if (newQuestion.pregunta.trim() === "") {
      newErrors.pregunta = "La pregunta no puede estar vacía.";
      isValid = false;
    } else if (newQuestion.pregunta.length > 35) {
      newErrors.pregunta = "La pregunta no puede tener más de 35 caracteres.";
      isValid = false;
    }

    // Validar el campo de respuesta
    if (newQuestion.respuesta.trim() === "") {
      newErrors.respuesta = "La respuesta no puede estar vacía.";
      isValid = false;
    }

    setErrors(newErrors);
    console.log("Errores después de validar", newErrors); // Depuración

    return isValid;
  };

  const handleCreate = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      const questionsCollection = collection(db, "preguntas_frecuentes");
      await addDoc(questionsCollection, newQuestion);
      fetchQuestions(); // Refrescar la lista de preguntas
      onClose(); // Cerrar el modal después de crear
      toast.success("Pregunta agregada satisfactoriamente!");
    } catch (error) {
      console.error("Error creando la pregunta:", error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <h3>Crear Nueva Pregunta</h3>
        <TextField
          label="Pregunta"
          name="pregunta"
          fullWidth
          margin="normal"
          value={newQuestion.pregunta}
          onChange={handleInputChange}
          error={!!errors.pregunta} // Marcar el input como erróneo
          inputProps={{ maxLength: 35 }} // Limitar la longitud a 35 caracteres
        />
        {errors.pregunta && (
          <Typography
            variant="body2"
            color="error"
            style={{ marginBottom: 10 }}
          >
            {errors.pregunta}
          </Typography>
        )}
        <TextField
          label="Respuesta"
          name="respuesta"
          fullWidth
          margin="normal"
          multiline
          rows={4}
          value={newQuestion.respuesta}
          onChange={handleInputChange}
          error={!!errors.respuesta} // Marcar el input como erróneo
        />
        {errors.respuesta && (
          <Typography
            variant="body2"
            color="error"
            style={{ marginBottom: 10 }}
          >
            {errors.respuesta}
          </Typography>
        )}
        <Button
          onClick={handleCreate}
          variant="contained"
          color="primary"
          style={{ marginTop: 20 }}
        >
          Crear
        </Button>
        <Button
          onClick={onClose}
          variant="contained"
          style={{ marginTop: 20, marginLeft: 10 }}
        >
          Cancelar
        </Button>
      </Box>
    </Modal>
  );
};

export default AddQuestionModal;
