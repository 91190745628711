import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const EditSubcategoryModal = ({ open, onClose, subcategory, onSave }) => {
  const [nombre, setNombre] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (open && subcategory) {
      setNombre(subcategory.nombre);
      setError(""); // Reset error message when modal opens
    }
  }, [open, subcategory]);

  const handleSave = () => {
    if (nombre.trim() === "") {
      setError("No se pueden guardar campos vacíos.");
      return;
    }

    const updatedSubcategory = {
      ...subcategory,
      nombre: nombre.trim(),
    };
    onSave(updatedSubcategory);
    setError(""); // Clear error message after successful save
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <h2>Editar Subcategoría</h2>
        <TextField
          label="Nombre"
          fullWidth
          margin="normal"
          value={nombre}
          onChange={(e) => setNombre(e.target.value)}
        />
        {error && <p style={{ color: "red" }}>{error}</p>}
        <Button variant="contained" color="primary" onClick={handleSave}>
          Guardar
        </Button>
      </Box>
    </Modal>
  );
};

export default EditSubcategoryModal;
