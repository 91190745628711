import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchUserInfo } from "../utils/functions"; // Asegúrate de que esta función obtenga el campo avatar
import "../styles/styles.css";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

// Iconos para la información del usuario
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SourceIcon from "@mui/icons-material/Source";

// Iconos para las tarjetas
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import InventoryIcon from "@mui/icons-material/Inventory";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import Grid3x3Icon from "@mui/icons-material/Grid3x3";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import Chip from "@mui/material/Chip";

export const User = () => {
  const { userId } = useParams(); // Obtén el userId de los parámetros de la URL
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const getUserInfo = async () => {
      const user = await fetchUserInfo(userId);
      setUserInfo(user);
    };
    getUserInfo();
  }, [userId]);

  return (
    <>
      <h2 className="titlePageInt">Datos del usuario</h2>
      <div className="user-container" s>
        {userInfo ? (
          <div className="user-details">
            <TableContainer component={Paper} className="user-info-table">
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={3} className="tableHeaderCell">
                      <div style={{ display: "flex", gap: 20 }}>
                        <div className="avatar-container">
                          {userInfo.photoURL ? (
                            <img
                              src={userInfo.photoURL}
                              alt="User Avatar"
                              className="avatar-image"
                            />
                          ) : (
                            <div className="default-avatar">
                              <AccountBoxIcon className="default-avatar-icon" />
                            </div>
                          )}
                          {userInfo.photoURL ? null : (
                            <p className="no-avatar-text">
                              No hay foto de perfil disponible
                            </p>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 10,
                          }}
                        >
                          <div className="badgeInfoUSerCount">
                            <ShoppingCartIcon />
                            <Chip label="5" color="info" />
                            <h4 style={{ marginLeft: 10 }}>Productos</h4>
                          </div>
                          <div className="badgeInfoUSerCount">
                            <LocalMallIcon />
                            <Chip label="15" color="info" />
                            <h4 style={{ marginLeft: 10 }}>Compras</h4>
                          </div>
                          <div className="badgeInfoUSerCount">
                            <InventoryIcon />
                            <Chip label="25" color="info" />
                            <h4 style={{ marginLeft: 10 }}>Ventas</h4>
                          </div>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="tableHeaderCell">
                      <Grid3x3Icon className="info-icon" />
                    </TableCell>
                    <TableCell>
                      <b>User ID:</b>
                    </TableCell>
                    <TableCell>{userId}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="tableHeaderCell">
                      <AccountCircleIcon className="info-icon" />
                    </TableCell>
                    <TableCell>
                      <b>Name:</b>
                    </TableCell>
                    <TableCell>{userInfo.displayName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="tableHeaderCell">
                      <EmailIcon className="info-icon" />
                    </TableCell>
                    <TableCell>
                      <b>Email:</b>
                    </TableCell>
                    <TableCell>{userInfo.email}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="tableHeaderCell">
                      <PhoneIcon className="info-icon" />
                    </TableCell>
                    <TableCell>
                      <b>Telefono:</b>
                    </TableCell>
                    <TableCell>{userInfo.telefono}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="tableHeaderCell">
                      <SourceIcon className="info-icon" />
                    </TableCell>
                    <TableCell>
                      <b>Cedula:</b>
                    </TableCell>
                    <TableCell>{userInfo.cedula}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="tableHeaderCell">
                      <CalendarTodayIcon className="info-icon" />
                    </TableCell>
                    <TableCell>
                      <b>Fecha de creación:</b>
                    </TableCell>
                    <TableCell>{userInfo.createdAt}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <Stack spacing={1}>
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            <Skeleton variant="circular" width={100} height={100} />
            <Skeleton variant="rectangular" width={210} height={60} />
            <Skeleton variant="rounded" width={210} height={60} />
          </Stack>
        )}
        <div className="history-container">
          <h3 className="history-title">Historial en ElPunto</h3>
          <div className="history-cards">
            <div className="history-card products-card">
              <ShoppingCartIcon className="card-icon" />
              <h4>Productos</h4>
              <p className="card-value">20</p>
            </div>
            <div className="history-card purchases-card">
              <LocalMallIcon className="card-icon" />
              <h4>Compras</h4>
              <p className="card-value">15</p>
            </div>
            <div className="history-card sales-card">
              <InventoryIcon className="card-icon" />
              <h4>Ventas</h4>
              <p className="card-value">10</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
