import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";

function ConfirmationModal({
  isVisible,
  onConfirm,
  onCancel,
  message,
  isLoading,
  successMessage,
}) {
  return (
    <Dialog open={isVisible} onClose={onCancel}>
      <DialogTitle>Confirmación</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <div
            style={{ display: "flex", justifyContent: "center", padding: 20 }}
          >
            <CircularProgress />
            <LinearProgress />
          </div>
        ) : (
          <div>
            <p>{message}</p>
            {successMessage && <p>{successMessage}</p>}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancelar
        </Button>
        <Button onClick={onConfirm} color="primary">
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationModal;
