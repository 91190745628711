// ProductStatusContext.js
import React, { createContext, useState, useContext } from "react";

const ProductStatusContext = createContext();

export function ProductStatusProvider({ children }) {
  const [numStatusCero, setNumStatusCero] = useState(0);

  return (
    <ProductStatusContext.Provider value={{ numStatusCero, setNumStatusCero }}>
      {children}
    </ProductStatusContext.Provider>
  );
}

export function useProductStatus() {
  return useContext(ProductStatusContext);
}
