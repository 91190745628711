import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BasicModal from "../components/BasicModal";
import ProductInfoById from "./ProductInfoById";
import { collection, getDocs } from "firebase/firestore";
import {
  getCategoryAndSubcategory,
  fetchUserInfo,
  getStatusText,
} from "../utils/functions";
import "../styles/styles.css";
import { useProductStatus } from "../ProductStatusContext"; // Importa el contexto
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";

function ProductsScreen() {
  const [products, setProducts] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedProductUserId, setSelectedProductUserId] = useState(null);
  const [userInfoMap, setUserInfoMap] = useState({});
  const [statusProd, setStatusProd] = useState(null); // Estado para almacenar el status del producto
  const [searchQuery, setSearchQuery] = useState(""); // Estado para el query de búsqueda
  const [page, setPage] = useState(0); // Estado para la página actual
  const [rowsPerPage, setRowsPerPage] = useState(5); // Estado para el número de filas por página
  const { setNumStatusCero } = useProductStatus(); // Obtén la función para actualizar el estado del contexto

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    if (selectedProductUserId) {
      const fetchAndSetUserInfo = async () => {
        const user = await fetchUserInfo(selectedProductUserId);
        setUserInfoMap((prevMap) => ({
          ...prevMap,
          [selectedProductUserId]: user,
        }));
      };
      fetchAndSetUserInfo();
    }
  }, [selectedProductUserId]);

  const fetchProducts = async () => {
    try {
      const productsCollection = collection(db, "productos");
      const productsSnapshot = await getDocs(productsCollection);

      const productsData = await Promise.all(
        productsSnapshot.docs.map(async (doc) => {
          const data = doc.data();
          const createAt = data.createAt
            ? data.createAt.toDate().toLocaleDateString()
            : "No Date";
          const { categoria, subcategoria, userId } = data;

          let categoriaNombre = "";
          let subcategoriaNombre = "";

          if (categoria && subcategoria) {
            try {
              const names = await getCategoryAndSubcategory(
                categoria,
                subcategoria
              );
              categoriaNombre = names.categoriaNombre;
              subcategoriaNombre = names.subcategoriaNombre;
            } catch (error) {
              console.error(
                "Error fetching category and subcategory names:",
                error
              );
            }
          }

          return {
            id: doc.id,
            ...data,
            createAt,
            categoriaNombre,
            subcategoriaNombre,
            userId,
          };
        })
      );

      // Ordena los productos con status 0 primero
      productsData.sort((a, b) => {
        if (a.status === 0 && b.status !== 0) return -1;
        if (a.status !== 0 && b.status === 0) return 1;
        return 0; // Mantiene el orden original si ambos tienen el mismo status
      });

      setProducts(productsData);

      // Contar productos con status: 0 y actualizar el contexto
      const countStatusCero = productsData.filter((p) => p.status === 0).length;
      setNumStatusCero(countStatusCero); // Actualiza el estado global del contexto

      // Obtener la información del usuario para cada producto
      const userIds = [
        ...new Set(productsData.map((product) => product.userId)),
      ];
      const userPromises = userIds.map((id) => fetchUserInfo(id));
      const userInfos = await Promise.all(userPromises);

      const userInfoMap = userInfos.reduce((map, userInfo) => {
        map[userInfo.uid] = userInfo;
        return map;
      }, {});

      setUserInfoMap(userInfoMap);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleViewClick = (productId, userId, status) => {
    setSelectedProductId(productId);
    setSelectedProductUserId(userId);
    setStatusProd(status); // Establece el estado del producto seleccionado
    setOpenModal(true);
  };

  const handleCloseModal = async () => {
    setOpenModal(false);
    setSelectedProductId(null);
    setUserInfoMap((prevMap) => ({
      ...prevMap,
      [selectedProductUserId]: null,
    }));
    // Vuelve a cargar los productos para reflejar los cambios
    await fetchProducts();
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setPage(0); // Resetear la página a 0 cuando se realiza una búsqueda
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Resetear la página a 0 cuando se cambian las filas por página
  };

  // Filtrar productos según la búsqueda
  const filteredProducts = products.filter((product) =>
    product.nombre.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Aplicar la paginación
  const paginatedProducts = filteredProducts.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <div style={{ padding: 20 }}>
      <h2>Productos</h2>
      <TextField
        label="Buscar Producto"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchQuery}
        onChange={handleSearch}
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="tableHeaderCell">Producto</TableCell>
              <TableCell className="tableHeaderCell">
                Categoría / Subcategoría
              </TableCell>
              <TableCell className="tableHeaderCell">Cantidad</TableCell>
              <TableCell className="tableHeaderCell">Precio</TableCell>
              <TableCell className="tableHeaderCell">Estado</TableCell>
              <TableCell className="tableHeaderCell">Creación</TableCell>
              <TableCell className="tableHeaderCell">Usuario</TableCell>
              <TableCell className="tableHeaderCell"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedProducts.map((product) => (
              <TableRow key={product.id}>
                <TableCell component="th" scope="row">
                  {product.nombre}
                </TableCell>
                <TableCell>
                  {product.categoriaNombre} / {product.subcategoriaNombre}
                </TableCell>
                <TableCell>{product.cantidad}</TableCell>
                <TableCell>{product.precio}</TableCell>
                <TableCell>{getStatusText(product.status)}</TableCell>
                <TableCell>{product.createAt}</TableCell>
                <TableCell>
                  <Link
                    to={`/User/${product.userId}`}
                    style={{ textDecoration: "none", color: "blue" }}
                  >
                    {userInfoMap[product.userId]?.nombre || "Cargando..."}
                  </Link>
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() =>
                      handleViewClick(
                        product.id,
                        product.userId,
                        product.status
                      )
                    }
                  >
                    <VisibilityIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredProducts.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Modal para mostrar detalles del producto */}
      <BasicModal
        open={openModal}
        onClose={handleCloseModal}
        component={ProductInfoById}
        componentProps={{
          productId: selectedProductId,
          userInfo: userInfoMap[selectedProductUserId],
          statusProd, // Pasa el status del producto al modal
          setStatusProd, // Pasa la función para actualizar el status del producto
        }}
        width="600px"
      />
    </div>
  );
}

export default ProductsScreen;
