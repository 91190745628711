import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import AddQuestionModal from "../components/questionModal/addQuestionModal";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import "../styles/styles.css";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  maxHeight: "80vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
};

// Componente para el modal de visualización de preguntas
const ViewQuestionModal = ({ open, onClose, question }) => {
  if (!question) return null;

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <h3>
          Pregunta:
          <br /> {question.pregunta}
        </h3>
        <br />
        <p>
          Respuesta:
          <br /> {question.respuesta}
        </p>
      </Box>
    </Modal>
  );
};

// Componente para el modal de confirmación de eliminación
const ConfirmDeleteModal = ({ open, onClose, onDelete }) => (
  <Modal
    open={open}
    onClose={onClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={modalStyle}>
      <h3>¿Estás seguro de que deseas eliminar esta pregunta?</h3>
      <br></br>
      <Button onClick={onDelete} variant="contained" color="secondary">
        Eliminar
      </Button>
      <Button onClick={onClose} variant="contained" style={{ marginLeft: 10 }}>
        Cancelar
      </Button>
    </Box>
  </Modal>
);

// Componente para el modal de edición de preguntas
const EditQuestionModal = ({ open, onClose, question, fetchQuestions }) => {
  const [updatedQuestion, setUpdatedQuestion] = useState({
    pregunta: question?.pregunta || "",
    respuesta: question?.respuesta || "",
  });

  useEffect(() => {
    if (question) {
      setUpdatedQuestion({
        pregunta: question.pregunta,
        respuesta: question.respuesta,
      });
    }
  }, [question]);

  const handleInputChange = (e) => {
    setUpdatedQuestion({ ...updatedQuestion, [e.target.name]: e.target.value });
  };

  const handleUpdate = async () => {
    try {
      const questionRef = doc(db, "preguntas_frecuentes", question.id);
      await updateDoc(questionRef, updatedQuestion);
      fetchQuestions();
      onClose();
      toast.success("Pregunta editada satisfactoriamente!");
    } catch (error) {
      console.error("Error updating question:", error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <h3>Editar Pregunta</h3>
        <TextField
          label="Pregunta"
          name="pregunta"
          fullWidth
          margin="normal"
          value={updatedQuestion.pregunta}
          onChange={handleInputChange}
        />
        <TextField
          label="Respuesta"
          name="respuesta"
          fullWidth
          margin="normal"
          value={updatedQuestion.respuesta}
          onChange={handleInputChange}
        />
        <Button
          onClick={handleUpdate}
          variant="contained"
          color="primary"
          style={{ marginTop: 20 }}
        >
          Actualizar
        </Button>
        <Button
          onClick={onClose}
          variant="contained"
          style={{ marginTop: 20, marginLeft: 10 }}
        >
          Cancelar
        </Button>
      </Box>
    </Modal>
  );
};

function QuestionsScreen() {
  const [questions, setQuestions] = useState([]);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    fetchQuestions();
  }, []);

  const fetchQuestions = async () => {
    try {
      const questionsCollection = collection(db, "preguntas_frecuentes");
      const questionsSnapshot = await getDocs(questionsCollection);

      const questionsData = questionsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setQuestions(questionsData);
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  const handleViewClick = (question) => {
    setSelectedQuestion(question);
    setOpenViewModal(true);
  };

  const handleDeleteClick = (question) => {
    setSelectedQuestion(question);
    setOpenDeleteModal(true);
  };

  const handleEditClick = (question) => {
    setSelectedQuestion(question);
    setOpenEditModal(true);
  };

  const handleCloseCreateModal = () => {
    setOpenCreateModal(false);
  };

  const handleCloseViewModal = () => {
    setOpenViewModal(false);
    setSelectedQuestion(null);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedQuestion(null);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedQuestion(null);
  };

  const handleDelete = async () => {
    try {
      await deleteDoc(doc(db, "preguntas_frecuentes", selectedQuestion.id));
      fetchQuestions();
      handleCloseDeleteModal();
      toast.success("Pregunta eliminada satisfactoriamente!");
    } catch (error) {
      console.error("Error deleting question:", error);
    }
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredQuestions = questions.filter((question) =>
    question.pregunta.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const paginatedQuestions = filteredQuestions.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <div style={{ padding: 20 }}>
      <h2>Preguntas Frecuentes</h2>
      <TextField
        label="Buscar Pregunta"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchQuery}
        onChange={handleSearch}
      />
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => setOpenCreateModal(true)}
      >
        Crear Nueva Pregunta
      </Button>
      <TableContainer component={Paper} style={{ marginTop: 20 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="tableHeaderCell">#</TableCell>
              <TableCell className="tableHeaderCell">Pregunta</TableCell>
              <TableCell className="tableHeaderCell" colSpan={3}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedQuestions.map((question, index) => (
              <TableRow key={question.id}>
                <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                <TableCell component="th" scope="row">
                  {question.pregunta}
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleViewClick(question)}>
                    <VisibilityIcon />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEditClick(question)}>
                    <EditIcon />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleDeleteClick(question)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={filteredQuestions.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <AddQuestionModal
        open={openCreateModal}
        onClose={handleCloseCreateModal}
        fetchQuestions={fetchQuestions}
      />

      <ViewQuestionModal
        open={openViewModal}
        onClose={handleCloseViewModal}
        question={selectedQuestion}
      />

      <ConfirmDeleteModal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        onDelete={handleDelete}
      />

      <EditQuestionModal
        open={openEditModal}
        onClose={handleCloseEditModal}
        question={selectedQuestion}
        fetchQuestions={fetchQuestions}
      />
    </div>
  );
}

export default QuestionsScreen;
