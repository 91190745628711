import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Modal,
  TextField,
  Paper,
  IconButton,
  TablePagination,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  collection,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { db } from "../firebase"; // Asegúrate de configurar firebase

const FilterWordsScreen = () => {
  const [filterWords, setFilterWords] = useState([]);
  const [filteredWords, setFilteredWords] = useState([]);
  const [open, setOpen] = useState(false);
  const [newWord, setNewWord] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const inputRef = useRef(null);

  const fetchFilterWords = async () => {
    const querySnapshot = await getDocs(collection(db, "filter_words"));
    const words = [];
    querySnapshot.forEach((doc) => {
      words.push({ id: doc.id, ...doc.data() });
    });
    setFilterWords(words);
    setFilteredWords(words);
  };

  useEffect(() => {
    fetchFilterWords();
  }, []);

  useEffect(() => {
    setFilteredWords(
      filterWords.filter((word) =>
        word.palabra.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, filterWords]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNewWord(""); // Limpiar el campo al cerrar el modal
  };

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus();
    }
  }, [open]);

  const handleAddWord = async () => {
    if (newWord.trim() === "" || newWord.includes(" ")) {
      toast.error("La palabra no puede tener espacios en blanco.");
      return;
    }

    try {
      await addDoc(collection(db, "filter_words"), { palabra: newWord });
      toast.success("Palabra agregada correctamente");
      setNewWord("");
      handleClose();
      fetchFilterWords();
    } catch (error) {
      toast.error("Error al agregar la palabra");
    }
  };

  const handleDeleteWord = async (id) => {
    if (window.confirm("¿Estás seguro de que deseas eliminar esta palabra?")) {
      try {
        await deleteDoc(doc(db, "filter_words", id));
        toast.success("Palabra eliminada correctamente");
        fetchFilterWords();
      } catch (error) {
        toast.error("Error al eliminar la palabra");
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleAddWord();
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <h2 className="titlePageInt">Filtro de palabras</h2>
      <div style={{ padding: 20 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="tableHeaderCell">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpen}
                    size="small"
                    style={{}}
                  >
                    Agregar palabra
                  </Button>
                </TableCell>
                <TableCell colSpan={2} className="tableHeaderCell">
                  <TextField
                    label="Buscar Palabra"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    size="small"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="tableHeaderCell">ID</TableCell>
                <TableCell className="tableHeaderCell">Palabra</TableCell>
                <TableCell className="tableHeaderCell"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredWords
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((word) => (
                  <TableRow key={word.id} sx={{ height: 20 }}>
                    <TableCell>{word.id}</TableCell>
                    <TableCell>{word.palabra}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleDeleteWord(word.id)}>
                        <DeleteIcon color="error" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={filteredWords.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
        <Modal open={open} onClose={handleClose}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "8px",
            }}
          >
            <h2>Agregar Palabra</h2>
            <TextField
              label="Nueva Palabra"
              value={newWord}
              onChange={(e) => setNewWord(e.target.value)}
              onKeyPress={handleKeyPress}
              fullWidth
              margin="normal"
              inputRef={inputRef}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddWord}
              fullWidth
            >
              Agregar
            </Button>
          </div>
        </Modal>
        <ToastContainer />
      </div>
    </>
  );
};

export default FilterWordsScreen;
